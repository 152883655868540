import { useHistory, useLocation } from 'react-router-dom';
import React from 'react';
import {  Button,  IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';

function defaultValue(value) {
  return value > -1 ? value : 0
}

function menuImage(value) {
  switch (value) {
    case 0:
      return <FormatListBulletedOutlinedIcon />
    case 1:
      return <ContactPhoneOutlinedIcon />
    case 2:
      return <InfoOutlinedIcon />
    default :
      return <ReceiptOutlinedIcon />
  }
}

const routeNames = ["Serviços", "Contactos", "Sobre Nós", "Solicitar Orçamento"]

function TabSelector(props) {


  const history = useHistory()
  let loc = useLocation().pathname
  React.useEffect(() => {
    let index = props.routes.indexOf(loc)
    if (index == -1)
      handleChange(0)
    else
      props.setValue(defaultValue(index))
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newValue) => {
    props.setValue(newValue);
    history.push(props.routes[newValue])
    handleClose()
  };

  return (
    <div>
      <Toolbar style={{ marginLeft: "-15px" }}>
        <IconButton onClick={handleClick}>
          <MenuOutlinedIcon />
        </IconButton>
        <Typography className="justified" variant="h5" color="primary">{routeNames[props.value]}</Typography>
        {props.value === 3 ? null :
          <React.Fragment>
            <div style={{ height: "40px", backgroundColor: "red", borderLeft: "1px", borderLeftColor: "grey", borderLeftStyle: "solid" }}></div>
            <Button
              style={{ marginLeft: "10px", width: "80%" }}
              variant="contained"
              color="primary"
              onClick={()=>handleChange(3)}
              startIcon={<ReceiptOutlinedIcon />}
            >
              {"Orçamento"}
            </Button>
          </React.Fragment>}
      </Toolbar>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {routeNames.map((value, index) =>
          <MenuItem key={index} selected={props.value === index} onClick={() => handleChange(index)}>
            <ListItemIcon>
              {menuImage(index)}
            </ListItemIcon>
            {value}
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

export default TabSelector;
