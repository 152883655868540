import '../App.css';
import serv_cont from '../images/servico_cont.svg'
import servico_seguro from '../images/servico_seguro.svg'
import servico_traducoes from '../images/servico_traducoes.svg'
import { Helmet } from "react-helmet-async";
import { Route, Switch } from 'react-router-dom';
import Service from './Service';
import Accountant from './Accountant';
function Services(props) {
  return (
    <div className="centered" style={{ width: "100%", height: "100%" }}>
      <Helmet>
        <title>Serviços - SCACF STUDIO</title>
        <meta name="description" content="Serviços: contabilidade, seguros, traduções. " />
        <meta name="keywords" content="scacf, contabilidade,seguradora,seguro,traduções" />
      </Helmet>
      <div className="tabTitle"></div>
      <Switch >
        <Route exact path={props.basePath}>
          <Service size="80%"  path="/servicos/contabilidade" onClick={() => { props.setSelectedService("") }} serviceName="Contabilidade" image={serv_cont}></Service>
          <Service size="70%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Seguros") }} serviceName="Seguros" image={servico_seguro}></Service>
          <Service size="75%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Traduções") }} serviceName="Traduções" image={servico_traducoes}></Service>

          <div className="tabTitle"></div>
        </Route>
        <Route path="/servicos/contabilidade">
          <Accountant selectTab={props.selectTab} setSelectedService={props.setSelectedService} />
        </Route>
      </Switch>
    </div >
  );
}

export default Services;
