import '../App.css';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react'
function Service(props) {

  return (
    <div className="centered" style={{marginTop:"5px",marginBottom:"5px",height:"100%",width:"100%"}} >
      <Link onClick={() => {
        if (props.onClick != null) {
          props.onClick()
        }
      }} className="service-image" style={{ backgroundSize:props.size,backgroundImage: `url(${props.image})` }} to={props.path}>
        <Typography variant="h4" color="primary" className="service-text-box">
          {props.serviceName}
        </Typography>
      </Link>

    </div >
  );
}

export default Service;
