import logo from './images/scacf_grande.png';
import './App.css';
import Facility from './facility/Facility';
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import TabSelector from './facility/Selector';
import { HelmetProvider } from 'react-helmet-async'
import Contacts from './facility/Contacts';
import Services from './facility/Services';
import Budget from './facility/Budget';


const routeMap = [
  "/",
  "/contactos",
  "/sobre",
  "/orcamento"
]

function App() {
  const [value, setValue] = React.useState(0);
  const [selectedService, setSelectedService] = React.useState("");

  return (
    <HelmetProvider>
      <div className="App">
        <div className="App-header">
          <Router>
            <Link className="non-selectable" onClick={() => { setValue(0) }} to="/">
              <div className="App-logo-sizer"  >
                <img src={logo} className="App-logo" alt="logo" />
              </div>
            </Link>
            <Paper className="menuPaper" elevation={3}>
              <TabSelector value={value} setValue={setValue} routes={routeMap} />
            </Paper>
            <Paper className="mainPaper">
              <Switch>
                <Route path="/contactos">
                  <Contacts />
                </Route>
                <Route path="/sobre">
                  <Facility selectTab={(tab) => { let value = routeMap.indexOf(tab); setValue(value > -1 ? value : 0) }} />
                </Route>
                <Route path="/orcamento">
                  <Budget setSelectedService={setSelectedService} selectedService={selectedService} selectTab={(tab) => { let value = routeMap.indexOf(tab); setValue(value > -1 ? value : 0) }} />
                </Route>
                <Route path="/servicos">
                  <Services setSelectedService={setSelectedService} basePath="/servicos" selectTab={(tab) => { let value = routeMap.indexOf(tab); setValue(value > -1 ? value : 0) }} />
                </Route>
                <Route path="/">
                  <Services setSelectedService={setSelectedService} basePath="/" selectTab={(tab) => { let value = routeMap.indexOf(tab); setValue(value > -1 ? value : 0) }} />
                </Route>
              </Switch>
            </Paper>
          </Router>
          <div style={{ backgroundSize: "10%", backgroundImage: `url(${logo})` }} className="background"></div>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default App;
