import '../App.css';
import online from '../images/online.svg'
import arquivo from '../images/arquivo.svg'
import IRSIRC from '../images/IRSIRC.svg'
import ivaies from '../images/ivaies.svg'
import dossier from '../images/dossier.svg'
import plano from '../images/plano.svg'
import RECIBO from '../images/RECIBO.svg'
import doccont from '../images/doccont.svg'
import planfin from '../images/planfin.svg'
import ordenado from '../images/ordenado.svg'
import impostos from '../images/impostos.svg'
import { Helmet } from "react-helmet-async";
import { Button, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react'
import SubService from './SubService';

function Accountant(props) {

  return (
    <React.Fragment>
      <Helmet>
        <title>Serviços de contabilidade - SCACF STUDIO</title>
        <meta name="description" content="Serviços: Arquivo digital de documentação, Contabilidade Online, Declarações de IRS, IRC, IVA e IES, Dossier Fiscal Anual, Elaboração de plano financeiro
        Gestão de documentos contabilísticos, Gestão financeira e comercial, Pagamento de impostos e Recibos de vencimento" />
        <meta name="keywords" content="scacf,contabilidade,arquivo,arquivo digital,documentação,online,declaração,IRS,IRC,IVA,IES,dossier fiscal,plano financeiro,gestão,documentos contabilísticos,
        gestão financeira,gestão comercial,impostos,ordenados,vencimento,recibos,faturas" />
      </Helmet>
      <div className="centered" style={{ position: "relative", width: "100%", paddingBottom: "10px" }}>

        <Typography variant="h4" color="primary" style={{ zIndex: 1, width: "100%",paddingTop:"5px", paddingBottom: "0px", height: "50px", position: "-webkit-sticky", position: "sticky", top: 0, backgroundColor: "white" }}>
          Contabilidade
        </Typography>
        <Grid container spacing={3} >
          <SubService size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Arquivo digital de documentação") }} serviceName="Arquivo digital de documentação" image={arquivo}></SubService>
          <SubService size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Contabilidade Online") }} serviceName="Contabilidade Online" image={online}></SubService>
          <SubService size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Declarações de IRS e IRC") }} serviceName="Declarações de IRS e IRC" image={IRSIRC}></SubService>
          <SubService size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Declarações de IVA e IES") }} serviceName="Declarações de IVA e IES" image={ivaies}></SubService>
          <SubService size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Dossier Fiscal Anual") }} serviceName="Dossier Fiscal Anual" image={dossier}></SubService>
          <SubService size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Elaboração de plano financeiro") }} serviceName="Elaboração de plano financeiro" image={plano}></SubService>
          <SubService size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Gestão de documentos contabilísticos") }} serviceName="Gestão de documentos contabilísticos" image={doccont}></SubService>
          <SubService size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Gestão financeira e comercial") }} serviceName="Gestão financeira e comercial" image={planfin}></SubService>
          <SubService size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Pagamento de impostos") }} serviceName="Pagamento de impostos" image={impostos}></SubService>
          <SubService size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Processamento de Ordenados") }} serviceName="Processamento de Ordenados" image={ordenado}></SubService>
          <SubService size="100% 90%" path="/orcamento" onClick={() => { props.selectTab("/orcamento"); props.setSelectedService("Contabilidade - Recibos de vencimento") }} serviceName="Recibos de vencimento" image={RECIBO}></SubService>
        </Grid>
        <Link className="link" style={{ marginTop: "10px", marginBottom: "5px", width: "90%" }} to="/orcamento">
          <Button onClick={() => { props.selectTab("/orcamento") }} className="link" style={{ width: "100%" }} variant="contained" color="primary">
            Solicitar Orçamento
        </Button>
        </Link>
        <Link className="link" style={{ width: "90%" }} to="/servicos">
          <Button className="link" style={{ width: "100%" }} variant="outlined" color="primary">
            Outros serviços
        </Button>
        </Link>

        <div style={{ width: "100%", height: "10px", position: "-webkit-sticky", position: "sticky", bottom: 0, backgroundColor: "white" }}>&nbsp;</div>
      </div>
    </React.Fragment>
  );
}

export default Accountant;
